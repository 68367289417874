import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { lineFix } from '~utils/popup-helpers';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  banner: {
    height: '60px',
    overflow: 'hidden',
    background: props =>
      props.promotional_box_background_color
        ? `${props.promotional_box_background_color}`
        : '#438dce',
    backgroundSize: 'cover',
    [theme.breakpoints.only('md')]: {
      height: '100px'
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
  linkStyle: {
    width: '100%%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  buttonStyle: {
    padding: '12px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      marginRight: 'unset'
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: 'unset'
    }
  },
  bannerText: {
    color: props => (props.text_color ? `${props.text_color}` : '#fff'),
    fontSize: '16px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  button: {
    backgroundColor: props =>
      props.button_background_color ? `${props.button_background_color}` : '#438dce',
    padding: '9px 26px ',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    lineHeight: '19px',
    boxShadow: 'unset',
    color: props => (props.button_text_color ? props.button_text_color : '#fff'),
    '&:hover': {
      backgroundColor: props =>
        props.button_background_color ? `${props.button_background_color}` : '#438dce',
      opacity: '0.8',
      textDecoration: 'none'
    }
  }
}));

const PromotionalBox = ({
  promotional_box_background_color,
  promotional_box_text,
  text_color,
  button_background_color,
  button_text_color,
  button_title,
  promotional_box_link,
  ...rest
}) => {
  const classes = useStyles({
    promotional_box_background_color,
    text_color,
    button_background_color,
    button_text_color
  });

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      alignItems={isMobileDevice ? 'flex-start' : 'center'}
      className={classes.banner}
      py={isMobileDevice ? 2 : 0}
      mt={1}
    >
      <Box
        component={Link}
        to={promotional_box_link}
        className={classes.linkStyle}
        display="flex"
        justifyContent="center"
        alignItems="center"
        underline="none"
        {...rest}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={isMobileDevice ? 2 : 3}
        >
          <Grid item xs={10} md="auto">
            <Typography className={classes.bannerText}>{lineFix(promotional_box_text)}</Typography>
          </Grid>
          <Grid item xs={10} sm={6} md="auto">
            <Button variant="contained" className={classes.button} fullWidth>
              {button_title}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

PromotionalBox.propTypes = {
  promotional_box_background_color: PropTypes.string.isRequired,
  promotional_box_text: PropTypes.string.isRequired,
  text_color: PropTypes.string.isRequired,
  button_background_color: PropTypes.string.isRequired,
  button_text_color: PropTypes.string.isRequired,
  button_title: PropTypes.string.isRequired,
  promotional_box_link: PropTypes.string.isRequired
};

export default PromotionalBox;
